<script setup lang='ts'>
import type { SCORE } from '@/shared/constants'

const props = withDefaults(defineProps<{
  type?: 'default' | 'detail'
  active?: number | 'all'
  score?: keyof typeof SCORE | null
}>(), {
  type: 'default',
  active: 0,
})

const router = useRouter()
function goPage(route: string): void {
  router.push(route)
}
</script>

<template>
  <div class="flex items-center">
    <template v-if="props.type !== 'detail'">
      <div class="flex items-center item active cursor-pointer" @click="goPage('/register')">
        <div class="item-content">
          <i class="i-step-1 item-icon" />
          <div class="item-text">
            注册会员
          </div>
        </div>
      </div>
      <div class="flex items-center item active cursor-pointer" @click="goPage('/publish')">
        <div class="item-arrow" />
        <div class="item-content">
          <i class="i-step-2 item-icon" />
          <div class="item-text">
            发表投诉
          </div>
        </div>
      </div>
    </template>

    <div class="flex items-center item active">
      <div v-if="type === 'default'" class="item-arrow" />
      <div class="item-content">
        <i class="i-step-3 item-icon" />
        <div class="item-text">
          专员审核
        </div>
      </div>
    </div>
    <div class="flex items-center item" :class="{ active: active === 'all' || active >= 1 || type === 'default' }">
      <div class="item-arrow" />
      <div class="item-content">
        <i class="i-step-4 item-icon" />
        <div class="item-text">
          企业处理
        </div>
      </div>
    </div>
    <div class="flex items-center item" :class="{ active: active === 'all' || active >= 2 || type === 'default' }">
      <div class="item-arrow" />
      <div class="item-content">
        <i class="i-step-5 item-icon" />
        <div class="item-text">
          企业回复
        </div>
      </div>
    </div>
    <div class="flex items-center item" :class="{ active: active === 'all' || active >= 3 || type === 'default' }">
      <div class="item-arrow" />
      <div class="item-content" :class="[score !== undefined && score !== null ? 'score-content' : '']">
        <i class="i-step-6 item-icon" />
        <div class="item-text">
          满意度打分<span v-if="score !== undefined && score !== null">({{ score }}分)</span>
        </div>
      </div>
    </div>
    <div class="flex items-center item" :class="{ active: active === 'all' || active >= 4 || type === 'default' }">
      <div class="item-arrow" />
      <div class="item-content">
        <i class="i-step-7 item-icon" />
        <div class="item-text">
          完成/再投诉
        </div>
      </div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.item{
    --bg-color: theme(colors.slate.300);
    --arrow-bg-color: theme(colors.slate.300);
  &.active {
    --bg-color: linear-gradient(135deg, #6ad5e5, #488afa);
    --arrow-bg-color: linear-gradient(-90deg, #5ac4d5, #488afa);
  }

  &-content {
    background: var(--bg-color);
    --uno: w-23.75 h-15 text-4 font-bold text-center flex items-center justify-center text-white rounded px-2;
    }
  &:nth-last-of-type(-n+2){
    .item-content{
      --uno: w-28;
    }
    .item-content.score-content{
      --uno: w-30;
    }
  }
  &-arrow{
    --uno: mr-0 h-9 w-10.5;
    background: var(--arrow-bg-color);
    clip-path: polygon(
      0% 20%,
      60% 20%,
      60% 0%,
      100% 50%,
      60% 100%,
      60% 80%,
      0% 80%
    );
  }
  &-text{
    --uno: text-4 font-bold text-center pl-0.5;
  }
  &-icon {
    --uno: flex-shrink-0 scale-140 w-9 h-9 overflow-hidden;
  }
}
</style>
